<template>
  <div class="task-config" v-loading="loading">
    <!-- <div class="config-item">
      <p class="config-title">任务设置</p>
      <div class="config-content">
        <div class="switch-config">
          <p class="form-title">任务开关</p>
          <el-switch v-model="isTask" :active-value="1" :inactive-value="0"></el-switch>
        </div>
        <div class="form-tip">
          <span class="el-icon-info" style="font-size: 15px;color:#C0C4CC"></span>
          <span>开启时需要完成任务才能成为正式会员，不开启时提交入会申请通过后即成为正式会员</span>
        </div>
      </div>
    </div> -->
    <div class="config-item">
      <p class="config-title">任务列表</p>
      <div class="config-content">
        <div class="form-tip">
          <span class="el-icon-info" style="font-size: 15px;color:#C0C4CC"></span>
          <span>初次完成任务后，后面再申请其他组织架构时，仅需要缴纳会费即算完成预备会员任务</span>
        </div>
        <el-table :data="tbody" class="thead-light" stripe style="width: 100%;margin-top: 23px">
          <!-- 操作 -->
          <el-table-column label="操作" width="150" fixed="right">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="EditRow(scope.row,scope.$index)"
                :disabled="scope.row.type === 'member' || !scope.row.status">
                编辑规则
              </el-button>
            </template>
          </el-table-column>
          <template v-for="(th, index) in thead">
            <el-table-column show-overflow-tooltip :prop="th.prop" :label="th.label" :width="th.width || ''"
              :sortable="th.sortable" :key="index">
              <template slot-scope="scope">
                <!-- 开启任务 -->
                <el-checkbox v-if="th.prop === 'status'" :true-label="1" :false-label="0"
                  v-model="scope.row.status" :disabled="scope.row.type === 'member'"
                  @change="(val) => statusChange(val,scope.row.id)"></el-checkbox>
                <!-- 普通 -->
                <span v-else>{{ scope.row[th.prop] | placeholder }}</span>
              </template>
            </el-table-column>
          </template>
        </el-table>
      </div>
    </div>

    <FixedActionBar>
      <!-- <el-button type="primary" @click="submit" :loading="saveLoading">保存</el-button> -->
      <el-button @click="cancle">取消</el-button>
    </FixedActionBar>
    <!-- <edit-rule-dialog v-if="tbody.length&& currentIndex !== -1" v-model="openEditRuleDialog"
      :ruleData.sync="tbody[currentIndex].rule">
    </edit-rule-dialog> -->
  </div>
</template>

<script>
import FixedActionBar from "@/base/layout/FixedActionBar";
import { taskData, statusTask } from "../../api/org-frame/task-config";
// import EditRuleDialog from "../../components/OrgFrame/EditRuleDialog.vue";
export default {
  components: { FixedActionBar },
  data() {
    return {
      loading: false,
      saveLoading: false,
      id: 0,
      thead: [
        { label: "ID", prop: "id" },
        { label: "任务内容", prop: "content" },
        { label: "开启任务", prop: "status" },
      ],
      tbody: [],
      currentIndex: -1,
      openEditRuleDialog: false,
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.getTaskData();
  },
  methods: {
    getTaskData() {
      this.loading = true;
      taskData({ id: this.id })
        .then((res) => {
          const { data } = res;
          this.tbody = data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    EditRow(row, index) {
      this.currentIndex = index;
      this.$router.push({
        name: "EditRule",
        params: { id: row.id, previousId: this.id },
      });
      // this.openEditRuleDialog = true;
    },
    // 修改任务开启状态
    statusChange(status, id) {
      this.loading = true;
      statusTask({ id, status })
        .then((res) => {
          this.$message.success(res.msg);
          this.getTaskData();
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 保存
    // submit() {
    //   this.saveLoading = true;
    //   const data = {};
    //   data.id = this.id;
    //   data.config = this.tbody;
    //   saveTask(data)
    //     .then((res) => {
    //       this.$message.success(res.msg);
    //       this.$router.push({ name: "CycOrganizationList" });
    //       this.saveLoading = false;
    //     })
    //     .catch(() => {
    //       this.saveLoading = false;
    //     });
    // },
    // 取消
    cancle() {
      this.$router.push({ name: "CycOrganizationList" });
    },
  },
};
</script>

<style lang="scss" scoped>
.task-config {
  .config-item + .config-item {
    margin-top: 79px;
  }
  .config-item {
    padding-top: 20px;
    .config-title {
      padding-left: 8px;
      font-size: 16px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      line-height: 16px;
      border-left: 3px #3479ef solid;
    }
    .config-content {
      margin-top: 32px;
      padding-left: 11px;
      .switch-config {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        .form-title {
          font-size: 14px;
          color: rgba(0, 0, 0, 0.85);
          line-height: 14px;
          margin-right: 15px;
        }
      }
      .form-tip {
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #606266;
        line-height: 12px;
        span + span {
          margin-left: 4px;
        }
      }
    }
  }
}
</style>